* {
  margin: 0;
  border: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}
html, body {
  width: 100%;
  height: 100%;
  color: #FFFFFF;
  background: #EE7571;
  text-align: center;
  font-size: 18px;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
  -webkit-text-size-adjust: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
h1, h2, h3 {
  display: inline;
  font-size: inherit;
  font-weight: inherit;
}
ul {
  list-style: none;
}
li, img, canvas {
  display: block;
}
a, a:hover, a:active, a:visited {
  text-decoration: none;
  color: inherit;
}
